<template>
  <div id="app">
    <Navbar />
    <div id="wrapper">
    <router-view/>
    </div>    
  </div>
</template>


<script>
import Navbar from './components/Navbar'
export default {
  name: 'App',
  components: {
    Navbar
  }

}
</script>

<style>
* {box-sizing: border-box;}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0px auto;
  max-width: 850px;
  border: 1px solid #AAA;
}

#wrapper {
  padding: 20px;
}
</style>