<template>
  <div id="defihome">
    <b-tabs content-class="mt-3">
      <b-tab title="Défis ouverts" active>
        <b-list-group>
          <p>Selectionner le défi que vous voulez répondre</p>
          <b-list-group-item
            v-for="defi in defisOpened"
            :key="defi.id"
            @click="openDefi(defi.id)"
          >
            Initié par: {{ defi.data.myname }}<br />
            Durée: {{ defi.data.duration }}s <br />
            Score: {{ defi.data.myscore }}
          </b-list-group-item>
        </b-list-group>
      </b-tab>
      <b-tab title="Défis terminés">
        <b-list-group>
          <b-list-group-item
            v-for="defi in defisEnded"
            :key="defi.id"
            @click="openDefi(defi.id)"
          >
            Initié par: {{ defi.data.myname }}<br />
            Durée: {{ defi.data.duration }}s <br />
            Score: {{ defi.data.myscore }}
          </b-list-group-item>
        </b-list-group>
      </b-tab>
      <b-tab title="Mes défis">
        <b-list-group>
          <b-list-group-item
            v-for="defi in defisStarted"
            :key="defi.id"
            @click="openDefi(defi.id)"
          >
            Initié par: {{ defi.data.myname }}<br />
            Durée: {{ defi.data.duration }}s <br />
            Score: {{ defi.data.myscore }}
          </b-list-group-item>
        </b-list-group>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import db from "./firebaseInit";
import firebase from "firebase";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      defisOpened: [],
      defisEnded: [],
      defisStarted: [],
    };
  },
  created() {
    db.collection("defis")
      .orderBy("date", "desc")
      .where("ended", "==", false)
      .limit(20)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.defisOpened.push({ id: doc.id, data: doc.data() });
        });
      });
    db.collection("defis")
      .orderBy("date", "desc")
      .where("ended", "==", true)
      .limit(20)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.defisEnded.push({ id: doc.id, data: doc.data() });
        });
      });
    db.collection("defis")
      .orderBy("date", "desc")
      .where("myuid", "==", firebase.auth().currentUser.uid)
      .limit(20)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.defisStarted.push({ id: doc.id, data: doc.data() });
        });
      });
  },
  methods: {
    openDefi: function (defi) {
      Swal.fire({ text: "Voulez-vous jouer ce défi?" ,
      showConfirmButton: true,
      showDenyButton: true,      
      }).then((r) => {
        if(r.isConfirmed)
        this.$router.push("/defi/" + defi);
      });
    },
  },
};
</script>

<style scoped>
div#defihome .list-group-item:hover {
  background: #99d6e5;
  cursor: pointer;
}
</style>