import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/Home.vue'
import About from '@/components/About.vue'
import Login from '@/components/Login.vue'
import Logout from '@/components/Logout'
import DefiHome from '@/components/DefiHome'
import DefiBoard from '@/components/DefiBoard'
import DefiEndGame from '@/components/DefiEndGame'
import firebase from 'firebase'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/defi/:id',
        name: 'DefiBoard',
        component: DefiBoard,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/defi/:id/end',
        name: 'DefiEndGame',
        component: DefiEndGame,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/defi',
        name: 'DefiHome',
        component: DefiHome,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            requiresGuest: true
        }
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/about',
        name: 'About',
        component: About
    }
]

let router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

//route guards

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        //if not logged in
        if (!firebase.auth().currentUser) {
            console.log("Not Logged in ")
            next({
                path: '/login',
                query: {
                    redirect: to.fullPath
                }
            });
        } else {
            next();
        }
    } else if (to.matched.some(record => record.meta.requiresGuest)) {
        //if not guest 
        if (firebase.auth().currentUser) {
            console.log("Logged in " + firebase.auth().currentUser.displayName)
            next({
                path: '/',
                query: {
                    redirect: to.fullPath
                }
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router