<template>
  <div class="hello">
    <div class="container">
      <div class="row">
        <div class="col s12">
          <p>Ireto avy ny lalao misy amin'izao</p>
          <b-button block variant="primary" to="/defi">Défi</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import db from "./firebaseInit";
export default {
  name: 'HelloWorld',
  data() {
    return {
      msg: "Mila miditra eto ambany ianao raha hilalao"
    }; 
  },
  created() {
    db.collection("defis")
    .limit(1)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          console.log(doc.data());
        });
      });
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
