<template>
  <div id="defihome"></div>
</template>

<script>
import db from "./firebaseInit";
import firebase from "firebase";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      user: null,
      defisOpened: [],
      defisEnded: [],
      defisStarted: [],
    };
  },
  beforeCreate() {
    //check
    const user = firebase.auth().currentUser;
    const defiID = this.$route.params.id;
    console.log(user.uid);
    db.doc("defis/" + defiID)
      .get()
      .then((doc) => {
        //if not ended
        console.log(doc.data());
        if (!doc.data().ended) {
          Swal.fire({
            text: "Ce jeu n'est pas encore terminé.",
            icon: "error",
            showConfirmButton: true,
          }).then(this.$router.push("/defi"));
          return;
        } 
        
        if (
          doc.data().myuserid !== user.uid ||
          doc.data().otheruid !== user.id
        ) {
          Swal.fire({
            text: "Vous ne participez pas à ce jeu.",
            icon: "error",
            showConfirmButton: true,
          }).then(this.$router.push("/defi"));
          return;
        }
      });
  },
  created() {},
  methods: {
    openDefi: function (defi) {
      Swal.fire({
        text: "Voulez-vous jouer ce défi?",
        showConfirmButton: true,
        showDenyButton: true,
      }).then((r) => {
        if (r.isConfirmed) this.$router.push("/defi/" + defi);
      });
    },
  },
};
</script>

<style scoped>
div#defihome .list-group-item:hover {
  background: #99d6e5;
  cursor: pointer;
}
</style>