<template>
  <div class="container">
    <div class="row">
      <div class="col s12 m8 offset-m2">
          <div class="login card-panel">

<p>Cette action nécessite une identification.</p>
            <b-button @click="login" class="btn btn-large grey lighten-4 black-text">S'identifier</b-button>
          </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase'

export default {
  name: "login",
  data: function() {
    return {};
  },
  methods: {
      login: function(e) {
          var provider = new firebase.auth.GoogleAuthProvider();
          console.log(e);
          firebase
          .auth()
          .signInWithRedirect(provider)
          .then(
              user => {
                  console.log(user)
                  this.$router.go({path: this.$router.path})
              }
          )
      }
  }
};
</script>