<template>
  <div id="defiboard">
    <b-container class="bv-example-row">
      <b-row class="text-center">
        <b-col>Score {{ score }}</b-col>
        <b-col cols="8">Cliquer sur une lettre pour former un mot</b-col>
        <b-col>Temps {{ duration }}</b-col>
      </b-row>
      <b-row class="text-center">
        <b-col>
          <div id="reponse">{{ reponse }}</div>
        </b-col>
      </b-row>
      <b-row class="justify-content-md-center">
        <b-col class="text-center">
          <b-button
            v-for="(tile, id) in tiles"
            v-bind:key="id"
            v-bind:class="hidden"
            @click="tileClick"
            v-bind:id="tile"
            variant="success"
            class="tile"
          >
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <b-button v-if="reponse" @click="showTiles" variant="primary"
            >Reessayer</b-button
          >
          <b-button @click="showSolution" variant="primary">Solution</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import db from "./firebaseInit";
import Swal from "sweetalert2";
//import firebase from "firebase";
//import axios from "axios";
function getRndId(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}
function shuffelWord(word) {
  var shuffledWord = "";
  word = word.split("");
  while (word.length > 0) {
    shuffledWord += word.splice((word.length * Math.random()) << 0, 1);
  }
  return shuffledWord;
}
export default {
  name: "DefiBoard",
  data() {
    return {
      score: 0,
      letterAvailable: [],
      timer: null,
      isPaused: false,
      isStopped: false,
      duration: 60,
      letters: 4,
      message_diso: "",
      show: false,
      hidden: "gone",
      tiles: [
        "tile-0",
        "tile-1",
        "tile-2",
        "tile-3",
        "tile-4",
        "tile-5",
        "tile-6",
      ],
      words: [],
      word: null,
      reponse: "",
      defisOpened: [],
      defisEnded: [],
      defisStarted: [],
    };
  },
  created() {
   if (document.addEventListener) {
    document.addEventListener('beforeunload', this.handlerClose, true);
} else if (document.attachEvent) {
    document.attachEvent('onbeforeunload', this.handlerClose);
}
  },
  mounted() {
    //get defi
    const defiID = this.$route.params.id;
    db.collection("defis")
      .doc(defiID)
      .get()
      .then((doc) => {
        const defi = doc.data();
        const myuid = defi.myuid;
        this.duration = defi.duration;
        this.letters = defi.letters;

        //get word list from defi
        db.collection("defis/" + defiID + "/" + myuid)
          .orderBy("date")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              if (doc.data().success == true) {
                this.words.push({ id: doc.id, word: doc.data().word });
              }
            });
            //if wordlist.size > 0
            this.prepareBoard();
            this.startTimer();
          });
      });
    

    // window.addEventListener("keypress", (e) => {
    //   this.keyEventHandler(String.fromCharCode(e.keyCode));
    // });

    //get word list

    // axios
    //   .get('http://localhost:5000/api/getword')
    //   .then(response => this.word = response.data)
  },
  methods: {
    handlerClose: function (e) {
      e.preventDefault();
      console.log(e);
      Swal.fire({
        icon: "warning",
        text: "Voulez-vous terminer ce jeu?",
        showDenyButton: true,
        denyButtonText: "Non",
        confirmButtonText: "Oui",
      }).then((r) => console.log(r));
      return false;
    },
    startTimer: function () {
      this.timer = setInterval(() => {
        if (!this.isPaused) {
          this.duration--;
        }
        if (this.duration <= 0) {
          this.gameOver();
        }
      }, 1000);
    },
    gameOver: function () {
      clearInterval(this.timer);
      Swal.fire({ text: "Temps expiré", allowOutsideClick: false }).then(() =>
        this.$router.push("/defi/" + this.defiID + "/end")
      );
    },
    handleLetter: function (letter) {
      this.reponse += letter;
      this.letterAvailable.splice(this.letterAvailable.indexOf(letter), 1);
      if (this.reponse.length == this.word.length) {
        //hamarino
        if (this.reponse === this.word) {
          //marina
          this.isPaused = true;
          this.score += 10;
          //update score in firestore

          //show dialog
          Swal.queue([
            {
              allowOutsideClick: false,
              confirmButtonText: "Définition",
              showDenyButton: true,
              denyButtonText: "Continue",
              text: "Bravo! vous l'avez trouvé!",
              showLoaderOnConfirm: true,
              customClass: {
                denyButton: "confirmClone",
              },
              preConfirm: () => {
                return db
                  .collection("teny")
                  .where("teny", "==", this.word.toLowerCase())
                  .get()
                  .then((querySnapshot) => {
                    var definitions = [];
                    querySnapshot.forEach((doc) => {
                      definitions.push(doc.data().dikany);
                    });
                    Swal.insertQueueStep({
                      text: definitions.join("\n"),
                      confirmButtonText: "Continue",
                    });
                  })
                  .catch(() => {
                    Swal.insertQueueStep({
                      icon: "error",
                      text: "Pas de definition",
                    });
                  });
              },
            },
          ]).then(() => {
            this.prepareBoard();
            this.isPaused = false;
          });
        } else {
          //diso
          Swal.fire({
            text: "Erreur, essaie encore.",
            showConfirmButton: false,
            timer: 700,
            icon: "error",
            showClass: {
              popup: "",
              icon: "",
            },
          }).then(() => {
            this.message_diso = "";
            this.showTiles();
          });
        }
      }
    },
    keyEventHandler: function (letter) {
      if (this.letterAvailable.includes(letter)) {
        this.handleLetter(letter);
      }
    },
    tileClick: function (event) {
      const letter = event.target.innerHTML;
      event.target.classList.add("hidden");
      this.handleLetter(letter);
    },
    prepareBoard: function () {
      // if (this.words.length > 0) {
      //   this.generateBoard(this.words[0].word);
      //   this.words.shift();
      // } else {
      //   //missing word
      const maxId = 26043;
      const minId = 5;

      var randomId = getRndId(minId, maxId);
      const docRef = db.collection("teny");

      //mixed letters
      var query;
      if (this.letters >= 4 && this.letters <= 7) {
        query = docRef
          .where("id", ">", randomId)
          .where("litera", "==", this.letters)
          .orderBy("id")
          .limit(1);
      } else {
        query = docRef.where("id", ">", randomId).orderBy("id").limit(1);
      }
      query.get().then((snapshot) => {
        this.generateBoard(snapshot.docs[0].data().teny);
      });

      // }
    },
    showTiles: function () {
      this.reponse = "";
      for (var i = 0; i < this.word.length; i++) {
        const elt = this.$el.querySelector("#" + this.tiles[i]);
        elt.classList.remove("gone");
        elt.classList.remove("hidden");
      }
    },
    showSolution: function () {
      Swal.queue([
        {
          html:
            "Afficher la solution pour ce mot?<br />Votre point sera reduit de <span class='badge badge-danger'>-5</span>.",
          allowOutsideClick: false,
          showDenyButton: true,
          denyButtonText: "Non",
          confirmButtonText: "Oui",
          showLoaderOnConfirm: true,
          preConfirm: () => {
            this.score -= 5;
            this.prepareBoard();
            return Swal.insertQueueStep({
              html: "Solution<br /><strong>" + this.word + "</strong>",
              confirmButtonText: "Continue",
            });
          },
        },
      ]);
    },
    generateBoard: function (word) {
      this.reponse = "";
      this.word = word.toUpperCase();
      word = shuffelWord(this.word);
      this.letterAvailable = word.split("");
      for (var i = 0; i < this.word.length; i++) {
        const elt = this.$el.querySelector("#" + this.tiles[i]);
        elt.classList.remove("gone");
        elt.classList.remove("hidden");
        elt.innerHTML = this.letterAvailable[i];
      }
    },
  },
};
</script>

<style scoped>
.center {
  justify-content: center;
}

div#action {
  margin: 0 auto;
  text-align: center;
}

div#action button {
  margin: 10px;
}
div#reponse {
  margin: 20px auto 100px;
  max-width: 50%;
  padding: 5px;
  border: 1px solid red;
  box-shadow: 0 0 10px #aaa;
  text-align: center;
  height: 2em;
  font-size: 2rem;
}
div.tile-group .btn {
  margin: 5px;
}
.hidden {
  visibility: hidden;
}
.gone {
  display: none;
}

.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.box-marina {
  text-align: center;
}

button {
  margin: 0.3em;
}

button.confirmClone {
  background-color: #2778c4 !important;
}
</style>